section.feature-cards {
    .card {
        @screen lg {
            transition: width 0.4s ease, color 0.4s ease;
            width: 40%;
            flex-grow: 1;
            min-height: 30rem;

            &.hovered {
                width: 60%;
                color: $white;
            }
        }
        
        &.bg-yellow {
            background: $yellow-bg;
        }

        &.bg-orange {
            background: $orange-bg;

            @screen lg {
                color: $white;
            }
        }

        ul {
            font-size: 0.875rem;

            li {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                &::before {
                    content: '';
                    width: 1.125rem;
                    height: 1.125rem;
                    flex-shrink: 0;
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 19"><circle cx="9" cy="9.979" r="6.75" fill="%23F9E31E"/><path stroke="%232C2C2C" stroke-linecap="round" stroke-linejoin="round" d="m6.75 9.979 1.5 1.5 3-3"/></svg>') 50% / contain no-repeat;
                }
            }
        }
        
        .card-image {
            aspect-ratio: 20.375 / 15;
            object-position: center;
            object-fit: cover;
        }
        
        .label {
            align-self: center;
            background: rgba($white, 0.4);
            font-size: 0.75rem;
            line-height: 1;
            padding: 0.375rem 1rem;
            border-radius: 1rem;
            transition: background-color 0.4s ease;
            
            @screen lg {
                align-self: end;
                color: $black;
            }
        }

        @screen lg {
            .card-bg {
                opacity: 0;
                transition: opacity 0.4s ease;
                
                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: rgba($black, 0.25);
                    border-radius: 0.5rem;
                }
            }

            &.hovered {
                .card-bg {
                    opacity: 1;
                }

                .label {
                    background: $sand;
                }
            }
        }
    }

}