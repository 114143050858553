section.front-hero {
    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        inset: 0 0 50%;
        background: linear-gradient(0deg, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0.2) 70.31%);

        @screen lg {
            inset: 20% 0 0;
            background: linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0.6) 100%);
        }
    }
    
    .container {
        grid-template-areas: "title" "label" "usp" "placeholder" "form" "video";
        grid-template-rows: auto auto auto 1fr auto auto;

        @screen md {
            grid-template-areas: "placeholder placeholder" "label video" "title video" "usp video" "form video";
            grid-template-rows: 1fr auto auto auto auto;
            grid-template-columns: auto 20rem;
        }
        
        @screen lg {
            grid-template-columns: auto 25rem;
        }
        
        @screen xl {
            grid-template-columns: 42.5rem 25rem;
        }
    }
    
    .small-label {
        grid-area: label;
    }
    
    .title {
        grid-area: title;
        
        > span:first-child {
            &::after {
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40"><g clip-path="url(%23a)"><path fill="%23F9E31E" d="M28.368 28.62H11.632V16.06L20 7.687l8.368 8.374V28.62ZM20 0C8.95 0 0 8.95 0 20s8.95 20 20 20 20-8.95 20-20S31.043 0 20 0Z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h40v40H0z"/></clipPath></defs></svg>');
            }
        }
    }
    
    .usp {
        grid-area: usp;
    }
    
    .placeholder {
        grid-area: placeholder;
    }
    
    .form-wrapper {
        background: rgba($black, 0.6);
        border: 1px solid $white;
        border-radius: 1rem;
        height: 3.125rem;
        margin-bottom: 1rem;
        transition: all 0.3s ease;
        
        @screen md {
            margin-bottom: 2rem;
            margin-top: 1rem;
            border-radius: 0;
            border-color: transparent transparent $white transparent;
            background: transparent;
            max-width: 25rem;

            &:hover, &:focus-within {
                border-radius: 1rem;
                border-color: $white;
                background: rgba($black, 0.6);
                
                input {
                    padding-left: 1rem;
                }
            }
        }
        
        input {
            flex: 1 1 0;
            background: transparent;
            border: none;
            height: 3rem;
            padding: 0.5rem 1rem;
            width: 0;
            
            @screen md {
                padding-left: 0;
                transition: all 0.3s ease;
            }

            &::placeholder {
                color: $gray-300;
                opacity: 1;
                
                @screen md {
                    color: $white;
                }
            }

            &:-ms-input-placeholder {
                color: $gray-300;

                @screen md {
                    color: $white;
                }
            }

            &::-ms-input-placeholder {
                color: $gray-300;

                @screen md {
                    color: $white;
                }
            }
        }
        
        button {
            border: none;
            background: transparent;
            padding: 0.5rem;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            white-space: nowrap;
            letter-spacing: 0.03rem;
            
            span {
                color: orange;
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                
                @screen md {
                    color: $yellow;
                }
                
                svg {
                    display: block;
                }
            }
        }
    }
    
    .video-wrapper {
        grid-area: video;
    }
    
}