/* dm-mono-300 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/dm-mono-v11-latin_latin-ext-300.woff2') format('woff2');
}

/* dm-mono-regular - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/dm-mono-v11-latin_latin-ext-regular.woff2') format('woff2');
}

.dm-mono {
    font-family: 'DM Mono', monospace;
}

/* inter-regular - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/inter-v12-latin_latin-ext-regular.woff2') format('woff2');
}
/* inter-500 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/inter-v12-latin_latin-ext-500.woff2') format('woff2');
}

.inter {
    font-family: 'Inter', sans-serif;
}